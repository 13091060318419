@font-face {
	font-family: 'Pretendard Variable';
	font-weight: 45 920;
	font-style: normal;
	font-display: swap;
	src: url('../font/PretendardVariable.woff2') format('woff2-variations');
}

body{font-family:'Pretendard Variable',sans-serif;margin:0;font-size:16px;letter-spacing:-0.5px;overflow-y:hidden;}
h1, h2, h3, h4, h5, h6, p{margin:0;font-size:inherit;}
/* b, */strong{font-weight:normal;}
ol, ul, li, dl, dt, dd{margin:0; padding:0;list-style:none;}
a, a:hover, a:focus{text-decoration:none;}
button{border:none;background:transparent;margin:0;padding:0;cursor:pointer;font-family:'Pretendard Variable',sans-serif;}
input, select{border:none;font-family:'Pretendard Variable',sans-serif;}
img{border:none;}

.hide{position:absolute;left:0;top:0;width:0;height:0;opacity:0;}
.bothEnd{display:flex;align-items:center;justify-content:space-between;}
.btn{display:inline-block;height:40px;padding:0 15px;border-radius:2px;line-height:40px;font-size:14px;font-weight:bold;color:#fff;text-align: center;}
.btn.low{height:32px;line-height:32px;}
.btn.blue{background:#2c78ff;}
.btn.orange{background:#F77D00}
.btn.red{background:#FF4622}
.btn.grey{background:#616161;}
.btn.off{background:#a7a7a7;pointer-events:none;}
.btn.disable{pointer-events:none;}
.btn.outline{border:1px solid #303030;color:#303030;}
.btn.blueoutline{border:1px solid #2c78ff;color:#2c78ff;}
.btn.orangeOutline{border:1px solid #F77D00;color:#F77D00;}
.btn.redOutline{border:1px solid #FF4622;color:#FF4622;}
.btn.outline.off{background: transparent;border-color: #a7a7a7;color:#a7a7a7;}
.btn.text{color:#303030;font-weight: 600;}
.btn.full{display: block;width:100%;box-sizing: border-box;}
.btn img{display: inline-block;height: 16px;margin-right: 5px;vertical-align: middle;}
.btn.pre img{margin-right: 5px;}
.btn.end img{margin-left: 5px;}
.btn.btnSm {height:24px; line-height:24px; font-size:12px; padding:0 13px;}

.divider{height: 1px;margin:50px 0;background: #ccc;}
.titleArea{margin-bottom:48px;flex-flow:wrap;}
.titleArea p {width:100%;font-size:14px;margin-top:13px}
.pageTitle{display: flex;align-items: center;gap:10px;font-size:24px;color:#303030;font-weight: 600;}
.pageTitle .total{font-size:18px;font-weight:normal;}
.pageTitle .total b{color:#217eff;font-weight:bold;}
.sectionTitle {font-size:14px;color:#1A1A1A;font-weight:700;margin-bottom:12px}
.contentWrap .btnWrap{display:flex;justify-content:flex-end;gap:10px;}
.contentWrap .btnWrap + table{margin-top:30px;}

form .formGroup{display: flex;align-items: flex-start;}
form .formGroup + .formGroup{margin-top: 60px;}
form .label{flex:none;display:block;width:256px;font-size: 14px;padding:10px 0;}
form .inputWrap{flex:1}
form .message{font-size:12px;margin: 5px 0 0 10px;color:#ef4f30;}
form .message.fine{color:#2c78ff;}
form .inputBox{display: flex; align-items: flex-start;gap:10px;}
form .inputBox .btn{height: 37px;line-height: 37px;}
form .inputBox .btn.outline.disable{color:#a7a7a7;border-color: #a7a7a7;}
form .input{flex:1;width:100%;height:37px;padding: 0 15px;border:1px solid #ccc;box-sizing:border-box;border-radius: 2px;font-size:14px; color:#303030;}
form .input.error{border-color: #ef4f30;}
form .input.disable{background: #f5f5f5;}
form .input:disabled{background: #f5f5f5;}
form .input::placeholder{color:#a7a7a7;}
form .input:hover{border-color: #616161;}
form .input:active{border-color:#2c78ff;}
form .input.input.disable:hover{border-color: #ccc;}
form .input:disabled:hover{border-color: #ccc;}
form .inputGroup{position: relative;padding:0;}
form .inputGroup .input{border:none;height: 35px;}
form .inputGroup img{position: absolute;right: 20px;top:10px;width:20px;height: 16px;opacity: 0;}
form .inputGroup img.fine{opacity: 1;}
